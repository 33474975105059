import React, { useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import logo from '../images/NTC-logo.jpg'

const MenuItems = ({ children }) => (
        <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
                {children}
        </Text>
)

const Header = props => {
        const [show, setShow] = useState(false)
        // const handleToggle = () => setShow(!show)

        return (
                <Flex
                        as="nav"
                        align="center"
                        justify="space-between"
                        wrap="wrap"
                        padding="1.5rem"
                        bg="#811672"
                        color="white"
                        {...props}
                >
                        <Flex align="center" mr={5}>
                                <img src={logo} alt="Logo" style={{ width: '70%' }} />
                                {/* <Heading as="h1" size="lg">
                                        Nicci Topping
                                </Heading> */}
                        </Flex>

                        {/* <Box display={{ sm: 'block', md: 'none' }} onClick={handleToggle}>
                                <svg fill="white" width="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <title>Menu</title>
                                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                                </svg>
                        </Box> */}

                        {/* <Box
                                display={{ sm: show ? 'block' : 'none', md: 'flex' }}
                                width={{ sm: 'full', md: 'auto' }}
                                alignItems="center"
                                flexGrow={1}
                        >
                                <MenuItems>Website</MenuItems>
                                <MenuItems>Blog</MenuItems>
                                <MenuItems>Stuff</MenuItems>
                        </Box>

                        <Box display={{ sm: show ? 'block' : 'none', md: 'block' }} mt={{ base: 4, md: 0 }}>
                                <Button bg="transparent" border="1px">
                                        Create account
                                </Button>
                        </Box> */}
                </Flex>
        )
}

export default Header
